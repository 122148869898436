<template>
	<div id="intention">
		<NavHeader title="意向提交"></NavHeader>
		<!-- <Header :navbg='true'></Header> -->
		<div class="follow-wrap">
			<div class="intr-wrap">
				<div class="title">提交区域选择，我们将为您<i>建设满意的Smart0住宅!</i></div>
				<div class="desc">Smart0采用全国创新的落地方式，你的家建在哪里由您决定！</div>
				<div class="smart0-flow">
					<img src="https://xcx-1257793586.cos.ap-beijing.myqcloud.com/fang/smart0/smart0-flow.png" alt="">
				</div>
				<div class="desc">提交表单后第一时间，我们将会安排品牌顾问联系您，为您提供专属服务，解答您对Smart0的一切疑问。</div>
			</div>
			<div class="house-wrap">
				<div class="house-list" v-if="houseList && houseList.length>0">
					<div class="house-item" v-for="(item,index) in houseList" :key="index">
						<div class="image">
							<img :src="item.image_max" alt="">
						</div>
						<div class="reserve">
							<i>{{item.name}}</i>
							<van-button @click="reserveTap(item.category_id)" round
								color="linear-gradient(90deg, #01CDF2 0%, #0FE993 100%)">立即预定</van-button>
						</div>
					</div>
				</div>
				<div class="house-img" v-else>
					<img src="../../../assets/images/smart0/follow-house.png" alt="">
				</div>
			</div>
			<van-field v-model="areaValue" is-link readonly label="地区选择" placeholder="请选择地区"
				@click="cascaderShow = true" required clearable />
			<van-field v-model="name" label="联系姓名" placeholder="请输入真实姓名" required clearable maxlength="10" />
			<van-field v-model="mobile" label="联系方式" placeholder="请输入手机号" required clearable type="number"
				maxlength="11" />
			<van-field v-model="email" label="电子邮箱" placeholder="请输入邮箱号" clearable />
			<van-popup v-model="cascaderShow" round position="bottom">
				<van-cascader v-model="cascaderValue" title="请选择地区" :options="regionList" :field-names="fieldNames"
					@close="cascaderShow = false" @finish="onFinish" active-color="#1989fa" />
			</van-popup>
			<van-button @click="submitTap" round color="#393C41" plain class="subbtn">立即提交</van-button>
			<div class="select-wrap">
				<div class="select" @click="selectTap">
					<img src="https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/duihao1.png"
						mode="widthFix" v-if="protocolChecked" />
					<img src="https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/duihao2.png"
						mode="widthFix" v-else />
				</div>
				<div class="document">
					<i @click="intentTap">意向提交及隐私说明</i>
				</div>
			</div>
			<div @click="followTap" class="link">
				我的意向<van-icon name="arrow" />
			</div>
			<div class="tips">·通过点击「提交订阅」按钮，即表示您同意西安小院科技所提供的信息，西安小院科技可按照客户隐私声明收集、使用、处理这些信息。</div>
			<div class="tips">·西安小院科技会将您的关注、订单更新状态和所有服务详情发送至您的邮箱及手机，请确保您的手机号码与电子邮箱畅通。</div>

		</div>
		<Footer />
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex'
	import Header from '@/components/head2022'
	import NavHeader from '@/components/nav-header.vue'
	import Footer from '@/components/foot2022'
	export default {
		components: {
			Header,
			Footer,
			NavHeader
		},
		data() {
			return {
				name: '',
				mobile: '',
				email: '',
				areaValue: '',
				cascaderValue: null,
				areaIdArr: [],
				houseList: [],
				regionList: [],

				fieldNames: {
					text: 'name',
					value: 'id',
					children: 'items',
				},
				cascaderShow: false,
				protocolChecked: false,
				pdf:'',
			}
		},
		computed: {
			...mapState(['ocApi', 'user_info', 'houseApi']),
		},
		created() {
			this.getRegionList();
			this.getAgreement();
		},
		activated() {
			this.areaValue=null;
			this.houseList=[];
			this.areaIdArr=[];
			if (this.user_info) {
				this.mobile = this.user_info.telephone;
				this.name = this.user_info.fullname;
			}
		},
		methods: {
			getAgreement: function() {
				this.$axios.get(this.ocApi + 'book/banner?name=live_user_service_privacy')
					.then(res => {
						if (res.data.data.status == 1) {
							this.pdf = res.data.data.images[0].link;
						} else {
							this.$message.error(res.data.message);
						}
					})
			},
			selectTap(){
				this.protocolChecked = !this.protocolChecked;
			},
			intentTap(){
				window.open(this.pdf);
			},
			onFinish({
				selectedOptions,
				value
			}) {
				this.areaIdArr=[];
				const newArr = [];
				selectedOptions.forEach(item => {
					newArr.push(item.name);
					this.areaIdArr.push(item.id)
				});
				this.areaValue = newArr.join("-");
				this.cascaderShow = false;
				this.gethouseList(value);
			},
			reserveTap(id) {
				this.$router.push({
					name: 'houseConfig',
					params: {
						id,
						houseType: 'smart0'
					}
				});
			},
			followTap() {
				this.$router.push({
					name: 'appointment',
					params: {
						tagName: 'intention',
					}
				});
			},
			gethouseList(area_id) {
				this.$axios.get(this.houseApi + 'house/area_house_project_list', {
						params: {
							type: 'smart0',
							area_id,
						}
					})
					.then(res => {
						this.houseList = res.data.data.homes_projects;
					})
					.catch(error => {
						this.$toast.fail(error.response.data.message);
					});
			},
			submitTap() {
				if (!this.protocolChecked) {
					this.$toast.fail("请阅读并同意隐私说明！");
					return false;
				}
				if (!this.name || !this.mobile || !this.areaValue) {
					this.$toast.fail("请将带*号的信息填写完整！");
					return false;
				}
				var arrLength = this.areaIdArr.length;
				var customer_id;
				if (this.user_info && this.user_info.customer_id) {
					customer_id = this.user_info.customer_id
				}
				this.$axios.post(this.ocApi + 'intentionCollection/registration', {
						name: this.name,
						telephone: this.mobile,
						email: this.email,
						zone_id: this.areaIdArr[0],
						city_id: arrLength > 1 ? this.areaIdArr[1] : '',
						county_id: arrLength > 2 ? this.areaIdArr[2] : '',
						customer_from: 'website_mobile',
						customer_id,
					})
					.then(res => {
						if (res.data.status == 1) {
							this.$dialog.confirm({
								showCancelButton:false,
								confirmButtonColor:'#3c9cff',
								confirmButtonText:'关闭',
								title: '提交成功',
								message: res.data.message,
							}).then(() => {
								this.$router.replace({
									path: 'Smart0',
								});
							});
						} else {
							this.$toast.fail(res.data.message);
						}
					})
					.catch(error => {
						this.$toast.fail(error.response.data.message);
					});
			},
			getRegionList() {
				this.$axios.get(this.ocApi + 'regions/get_zone_and_city')
					.then(res => {
						if (res.data.countries[0].status == 1) {
							this.regionList = res.data.countries[0].items;
							// 处理区域为空数据
							for (let arr of this.regionList) {
								arr.items.forEach((item, index) => {
									if (item.items.length < 1) {
										Reflect.deleteProperty(item, 'items');
									}
								})
							}

						} else {
							this.$toast.fail(res.data.message);
						}
					})
					.catch(error => {
						this.$toast.fail(error.response.data.message);
					});
			}
		}
	}
</script>

<style lang="scss">
	#intention {
		.follow-wrap {
			width: 100%;
			text-align: center;
			
			.subbtn {
				width: 50%;
				margin: 20px 0 6px;
				height: 32px;
				font-size: 12px
			}
			.intr-wrap{
				width: 82%;
				margin: 0 auto;
				.title {
					font-size: 24px;
					line-height: 28px;
					margin: 60px 0 19px;
					i{
						font-size: 26px;
						font-weight: 800;
					}
				}
				.smart0-flow{
					height: 34px;
					margin: 10px 0 15px;
					img{
						width: 100%;
					}
				}
				.desc {
					font-size: 12px;
					line-height: 18px;
					font-weight: 800;
					text-align: left;
				}
			}
			

			.el-input__inner {
				width: 480px;
				border-radius: 10px;
			}

			.info-item {
				margin-bottom: 40px;
				font-size: 22px;

				.label {
					font-size: 22px;
					line-height: 22px;
					margin-bottom: 13px;
				}

				.right {
					font-size: 18px;
				}

				i {
					color: #FF0000;
				}
			}

			.link {
				color: #33ddab;
				text-decoration: underline;
				margin-bottom: 8px;
			}
			.select-wrap{
				display: flex;
				align-items: center;
				margin: 5px 0 10px;
				font-size: 14px;
				justify-content: center;
			}
			.document{
				text {
					color: #0de699;
				}
			}
			.select {
				width: 20px;
				height: 20px;
				border-radius: 100%;
				overflow: hidden;
				margin-right: 10px;
				img {
					width: 100%;
				}
			}

			.tips {
				font-size: 8px;
				font-weight: 200;
				color: #595959;
				line-height: 16px;
				text-align: left;
				padding: 0 15px;
				margin-bottom: 5px;
			}

			.house-wrap {
				padding: 0 17px;

				.house-img {
					margin-top: 30px;

					img {
						width: 100%;
					}
				}

				.house-list {
					margin-top: 20px;

					.house-item {
						margin: 0 auto 20px;
						width: 80%;

						.image {

							img {
								width: 100%;
								height: auto;
								border-radius: 4px;
							}
						}

						.reserve {
							display: flex;
							justify-content: space-between;
							align-items: center;
							height: 50px;

							i {
								font-size: 16px;
							}

							.van-button--round {
								font-size: 12px;
								height: 32px;
								width: 30%;
							}
						}
					}
				}
			}
		}
	}
</style>